@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Unbounded&display=swap");
/*
@font-face {
  font-family: "Web3-Regular";
  src: url("./assets/fonts/Web3-Regular.woff") format("woff"),
    url("./assets/fonts/Web3-Regular.woff2") format("woff2");
}

.web3-regular {
  font-family: "Web3-Regular";
} */

body {
  font-family: "Inter";
}

.unbounded {
  font-family: "Unbounded";
}

@layer components {
  .bordered-action {
    @apply px-4 py-2 border border-neutral-200 rounded-4xl;
  }
}
